import { React } from 'react'
import './meetup.css'
import { getLang, slug, tr, icap } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout.jsx'
import { card as basicsCard } from '../../courses/basics/Course.jsx'
import { card as buildersCard } from '../../courses/builders/Course.jsx'
import { card as businessCard } from '../../courses/business/Course.jsx'
import { card as distrosCard } from '../../courses/distros/Course.jsx'
import { card as investorsCard } from '../../courses/investors/Course.jsx'
import { card as wCard } from '../../courses/why/Why.jsx'
import Card from '../../../components/card/Card.jsx'
import Paragraph from '../../../components/page/Paragraph.jsx'

// Ayn Rand on Money
// https://youtu.be/Z9HbQsTgrtk

const Meetup = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr('meetup:title'))}</h1>
        <Paragraph text={icap(tr('location')) + ': ' + tr('meetup:location')} />
        <Paragraph text={icap(tr('date')) + ': ' + tr('meetup:date')} />
        <Paragraph text={icap(tr('price')) + ': ' + tr('meetup:price')} />
        <Paragraph text={tr('meetup:content.1')} />
        <Paragraph text={tr('meetup:content.2')} />
        <RegistrationForm />
        {/* <p>{tr('courses:intro')}</p>
        <ul>
          <li>
            <a href={`/${slug('courses')}/${slug('why')}`}>{tr(wCard.title)}</a>
            <p>{tr(wCard.blurb)}</p>
          </li>
        </ul>
        <h2>{tr('Course Selection')}</h2>
        <p>{tr('Course Selection.1')}</p>
        <p>{tr('Course Selection.2')}</p>
        <h2>{tr('Refund Policy')}</h2>
        <p>{tr('Refund Policy.1')}</p>
        <p>{tr('Refund Policy.2')}</p> */}
      </div>
    </PageLayout>
  )
}

const RegistrationForm = () => {
  return (
    <iframe
      frameborder="0"
      width="400"
      height="900"
      src="https://lnbits.satoshidnc.com/events/embed/br3xyP7BNZygBd52BhhKLx"></iframe>
  )
}

export default Meetup