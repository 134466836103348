import React from 'react'
import './page-layout.css'

const PageLayout = ({ children }) => {
  return (
    <div className='page'>
      {children}
    </div>
  )
}

export default PageLayout