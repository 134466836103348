import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import './index.css'

import { init, globals } from './global-pure.js'
init(window.location.href)

window.addEventListener('mouseup', function(e){   
  let m = document.getElementById('popupmenu')
  if (!m) return
  if (m.style.display !== 'none' && !m.contains(e.target)) {
    globals.menuCloser()
  }
});

ReactDOM.render(<App />, document.getElementById('root'))
