import init_es from "./languages/es-PY";
import init_en from "./languages/en-US";
import init_de from "./languages/de-DE";
import { getPublicKey } from 'nostr-tools'
import * as nip19 from 'nostr-tools/nip19'
import { Buffer } from 'buffer'

export function slug(s,l=undefined) { return tr(s,l)?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(' ','-').toLowerCase() || s.toLowerCase() }
export function nbsp(s) { return s.replace(' ', '\u00a0') }
export function data(s) { return s.match(/.{1,2}/g).map(h => {
  const c = parseInt(h.padEnd(2,'0'), 16)
  const b = 0x2800 + ((c>>7)&1) + ((c>>5)&2) + ((c>>3)&4) + (c&8) + ((c<<2)&16) + ((c<<4)&32) + ((c<<2)&64) + ((c<<7)&128)
  return String.fromCodePoint(b)
}).join('')}
export function hsec() { return window.localStorage.getItem('nsec')||(window.localStorage.setItem('nsec', Buffer.from(crypto.getRandomValues(new Uint8Array(32))).toString('hex'))||window.localStorage.getItem('nsec')) }
export function hpub() { return getPublicKey(Buffer.from(hsec(), 'hex')) }
export function hpubh() { return hpub().match(/.{1,2}/g).map(h => h.substring(0,1)).join('') }
export function hpubl() { return hpub().match(/.{1,2}/g).map(h => h.substring(1,2)).join('') }
export function npub() { return nip19.npubEncode(hpub()) }
export function nsec() { return nip19.nsecEncode(Buffer.from(hsec(), 'hex')) }
export function icap(str) { return str.charAt(0).toUpperCase() + str.slice(1); }
export function cap(str) { return str.toUpperCase(); }
export function tcase(str) {
	return str.split(' ').map(function(word) {
		return word.replace(word[0], word[0].toUpperCase());
	}).join(' ');
}
export function coerceLang(l) {
  if (!supportedLangs.includes(l)) {
    for (let lc of supportedLangs) {
      if (lc.startsWith(l)) {
        return lc;
      }
    }
  }
  return l
}
export function deduceLanguage(s) {
  let n = 0, hitLang = '';
  for (const l of supportedLangs) {
    if (untr(s, l)) { n++; hitLang = l; }
  }
  if (n===1) return hitLang;
}
export function deduceRouteLanguage(r) {
  let parts = r.split('/')
  for (let part of parts) {
    let l = deduceLanguage(part);
    if (l) {
      return l;
    }
  }
}

export function init(url) {
  init_es(lang_db)
  init_en(lang_db)
  init_de(lang_db)

  let l = getLang()
  if ((l = deduceRouteLanguage(url))) {
    if (l !== curLang) {
      console.log('switch to',l)
      window.localStorage.setItem('lang', JSON.stringify(l))
      curLang = l;
    }
  }
}

export const globals = {};

export const lang_db = {};
const trs = {};
export const supportedLangs = ['es-PY', 'en-US', 'de-DE'];
const langFallbacks = {'gn-PY': ['es-PY']};
export function untr(s, l) {
  let item = Object.entries(lang_db[l]).filter(e => e[1].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('-',' ').toLowerCase() === s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('-',' ').toLowerCase())
  if (item.length===1) return item[0][0]
  return undefined
}
let curLang;
export function getLang() {
  if (curLang) return curLang;
  let l = JSON.parse(window.localStorage.getItem('lang') || '"es"');
  console.log('getLang() found',l)
  l = coerceLang(l)
  curLang = l;
  console.log('getLang() returning',curLang)
  return curLang;
}
export function tr(s, langOverride = undefined) {
  let l = getLang()
  if (langOverride) l = langOverride;
  l = coerceLang(l)
  const enableWarnings = false;
	if (!(s in trs)) {
		trs[s] = {'source': s};
		for (let supLang of supportedLangs) {
			if (typeof lang_db[supLang] === 'object'
			&& typeof lang_db[supLang][s] === 'string') {
				trs[s][supLang] = lang_db[supLang][s];
			}
		}
		var missing = '';
		for (let supLang of supportedLangs) {
			if (!(supLang in trs[s])) missing += ', ' + supLang;
		}
		if(missing !== '') {
			missing = missing.substring(2);
			if (enableWarnings) console.log("WARNING: Translation(s) "+missing+" missing for", trs[s]);
		}
	}
	if (l in trs[s]) {
		return trs[s][l];
	} else {
		let fallbacks;
		if (!(l in langFallbacks)) {
			fallbacks = ['en-US'];
		} else {
			fallbacks = [...langFallbacks[l], 'en-US'];
		}
		let best = -1, bestL = '';
    let v;
		for(v in trs[s]) {
			if(!trs[s].hasOwnProperty(v) || v === 'source') continue;
			let i = fallbacks.indexOf(v);
			if ((i >= 0 && i < best) || best === -1) {
				best = i;
				bestL = v;
			}
		}
		if (bestL) {
			if (enableWarnings) console.log("WARNING: Using '"+v+"' instead of '"+l+"' for", trs[s]);
			return trs[s][v];
		}
		if (enableWarnings) console.log("WARNING: No translations found for", trs[s]);
		return trs[s]['source'];
	}
}

export function currentPage() {
  let x = window.location.href
  let p = x.indexOf('://')
  x = p >= 0 ? x.substring(p + 3) : x
  p = x.indexOf('/')
  x = p >= 0 ? x.substring(p + 1) : x
  return x
}

// new TextDecoder().decode(base64ToBytes("YSDEgCDwkICAIOaWhyDwn6aE"))
function base64ToBytes(base64) {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

// bytesToBase64(new TextEncoder().encode(msg))
function bytesToBase64(bytes) {
  const binString = String.fromCodePoint(...bytes);
  return btoa(binString);
}

export function dmSatoshi(msg) {
  fetch("https://satoshidnc.com/message", {
    method: "POST",
    body: msg,
    headers: {
      'Accept': 'text/plain',
      "Content-type": "text/plain"
    }
  });
}