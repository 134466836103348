import { React } from 'react'
import { slug, icap, tr } from '../../../../global-pure.js'
import PageLayout from '../../../../containers/page/PageLayout.jsx'
import AskLink from '../../../../components/AskLink.jsx'
import TryItNowLink from '../../../../components/TryItNowLink.jsx'
import cardPic from '../../../../assets/apps/ng.jpg'

export const card = {
  title: 'ng-pos:title',
  blurb: 'ng-pos:blurb',
  subtitle: 'ng-pos:subtitle',
  routeBuilder: () => `/${slug(tr('apps'))}/${slug(tr('nuevoguarani'))}/${slug(tr('pos'))}`,
  imgUrl: cardPic,
  call: 'see features',
  // ask: <AskLink text={'purchase now'} prefill={'ant-s19j:question'} />
}

const PoS = () => {
  return (
    <PageLayout>
    <div className='content'>
      <h1>{icap(tr(card.title))}</h1>
      <h2>{tr(card.subtitle)}</h2>
      <h4>{icap(tr('status'))+':'}</h4>
      <p>{tr('ng-pos:status')}</p>
      <h4>{icap(tr('platforms'))+':'}</h4>
      <ul>
        <li><p>{tr('software-platform:android-native')}{' (download: '}<TryItNowLink text={'NuevoGuarani-v0.1.6-debug.apk'} url='https://dev-ng.satoshidnc.com/release/NuevoGuarani-v0.1.6-debug.apk'/>{')'}</p></li>
        <li><p>{tr('software-platform:web-browser')}, <TryItNowLink text={tr('try it now')} url='https://dev-ng.satoshidnc.com'/>!</p></li>
      </ul>
      <h4>{icap(tr('key-features'))+':'}</h4>
      <ul>
      <li><p>{tr('ng-pos:feature1')}</p></li>
      <li><p>{tr('ng-pos:feature2')}</p></li>
      <li><p>{tr('ng-pos:feature3')}</p></li>
      <li><p>{tr('ng-pos:feature4')}</p></li>
      <li><p>{tr('ng-pos:feature5')}</p></li>
      </ul>
      <h4>{icap(tr('specifications'))+':'}</h4>
      <ul>
      <li><p>{tr('ng-pos:spec1')}</p></li>
      <li><p>{tr('ng-pos:spec2')}</p></li>
      <li><p>{tr('ng-pos:spec3')}</p></li>
      </ul>
      <h4>{icap(tr('license'))+':'}</h4>
      <ul>
      <li><p>{tr('ng-pos:licensing')}<TryItNowLink text={tr('Satoshi Fairware License')} url='/licenses'/>{tr('ng-pos:licensing2')}</p></li>
      </ul>
      <h4>{icap(tr('security'))+':'}</h4>
      <p>{tr('ng-pos:security')}</p>
      <p>{icap(tr('Questions?'))}{' '}<AskLink /></p>
    </div>
  </PageLayout>
)
}

export default PoS