import React, { useEffect } from 'react'
import './navbar.css'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import { useState } from 'react'
import { globals, icap, nbsp, slug, tr, data, hpub, hpubh, hpubl, npub, hsec, nsec } from '../../global-pure.js'
import satoshi from "../../assets/satoshi-monument.png"
import { useLocation } from 'react-router-dom'

const MenuLink = ({ keyStr }) => {
  const location = useLocation();
  return (
    <p className={`dnc__navbar-link ${decodeURI(location.pathname).startsWith(`/${slug(keyStr)}`) ? 'in-route' : ''}`}>
      <a href={`/${slug(keyStr)}`}>
        {`${icap(tr(keyStr))}`}
      </a>
    </p>
  )
}

const Menu = () => {
  return (
    <>
      <MenuLink keyStr='events' />
      <MenuLink keyStr='library' />
      <MenuLink keyStr='apps' />
      <MenuLink keyStr='products' />
      <MenuLink keyStr='services' />
      {/* <MenuLink keyStr='market' /> */}
      <MenuLink keyStr='contact' />
    </>
  )
}

const Npub = () => {
  return (
    <>
    <span style={{
      display: 'inline-block',
      fontSize: '8pt',
      fontWeight: '900',
      lineHeight: '11.2px',
      letterSpacing: '-2.2px',
      backdropFilter: 'blur(5px)',
      cursor: 'copy'
    }}
    onClick={e => {
      e.target.style.cursor = 'inherit'
      setTimeout(()=>{e.target.style.cursor = 'copy'}, 1000)
      navigator.clipboard.writeText(e.ctrlKey?e.altKey?hsec():nsec():e.altKey?hpub():npub())
    }}>{data(hpubh())}<br/>{data(hpubl())}</span>
    </>
  )
}

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)
  useEffect(() => {
    globals.menuCloser = () => {
      // `this` refers to our react component
      setToggleMenu(false)
    }
  })

  return (
    <div className="dnc__navbar" style={{ backgroundImage: `url(${satoshi})` }}>
      <div className="dnc__navbar-logo">
      <a href="/">{nbsp(tr('Satoshi, D.N.C.'))}</a>
      {/* <span style={{ lineHeight: '22px' }}><br/></span><Npub/> */}
      </div>
      <div className="dnc__navbar-links_container">
        <div className="dnc__navbar-links">
          <Menu />
        </div>
        <div className="dnc__navbar-sign">
          {/* <p>Sign in</p>
          <button type="button">Sign up</button> */}
        </div>
        <div className="dnc__navbar-menu">
          {toggleMenu
            ? <RiCloseLine id="menuclose" color="#fff" size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
          }
          {toggleMenu && (
            <div id='popupmenu' className="dnc__navbar-menu_container scale-up-tr">
              <div className="dnc__navbar-menu_container-links">
                <Menu />
                <div className="dnc__navbar-menu_container-links-sign">
                  {/* <p>Sign in</p>
                  <button type="button">Sign up</button> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Navbar