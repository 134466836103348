import React from 'react'
import { slug, tr } from '../../global-pure.js'

const schemes = [
  'http',
  'https',
  'lightning',
]

const Paragraph = ({ text, indent }) => {
  const style = indent? { paddingLeft: '2em' }: {}
  text = text.replace('{listitem}', '')
  const result = []
  if (text.match(/{link:.*}/)) {
    let parts = text.split('{link:')
    result.push(parts[0])
    for (let i = 1; i < parts.length; i++) {
      const fields = parts[i].substr(0, parts[i].indexOf('}')).split('^')
      result.push(<a key={i} href={schemes.includes(fields[0].split(':',2)[0])?fields[0]:`/${fields[0].split('/').map(s => slug(s)).join('/')}`} target='_blank' noopener='true' noreferrer='true'>{tr(fields[1])}</a>)
      result.push(parts[i].substr(parts[i].indexOf('}')+1))
    }
    return (<p style={style}>{result}</p>)
  }
  return (<p style={style}>{text}</p>)
}

export default Paragraph
