import { React } from 'react'
import { icap, tr } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout.jsx'
import AskLink from '../../../components/AskLink'
import cardPic from '../../../assets/courses/builders.png'

const slugname = 'builders'

export const card = {
  date: slugname + '-course:date',
  title: slugname + '-course:title',
  blurb: slugname + '-course:blurb',
  subtitle: slugname + '-course:subtitle',
  routeBuilder: () => `/${tr('courses')}/${tr(slugname)}`,
  imgUrl: cardPic,
  call: 'course details'
}

const CoursePage = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{tr(card.title)}</h1>
        <h2>{tr(card.subtitle)}</h2>
        <p>{icap(tr('dates'))+': '}{tr(card.date)}</p>
        <h4>{icap(tr('schedule'))+':'}</h4>
        <ul>
          <li><p>{tr('builders:option1')}</p></li>
        </ul>
        <h4>{icap(tr('this course is for'))+':'}</h4>
        <p>{icap(tr('owners'))}</p>
        <h4>{icap(tr('this course includes'))+':'}</h4>
        <ul>
          <li><p>{tr('builders:bullet1')}</p></li>
          <li><p>{tr('builders:bullet2')}</p></li>
          <li><p>{tr('builders:bullet3')}</p></li>
          <li><p>{tr('builders:bullet4')}</p></li>
          <li><p>{tr('builders:bullet5')}</p></li>
        </ul>
        <h4>{icap(tr('NOT included in course'))+':'}</h4>
        <ul>
          <li><p>{tr('builders:bulletx1')}</p></li>
          <li><p>{tr('builders:bulletx2')}</p></li>
        </ul>
        <h4>{icap(tr('prerequisites'))+':'}</h4>
        <ul>
          <li><p>{tr('builders:prereq1')}</p></li>
          <li><p>{tr('builders:prereq2')}</p></li>
        </ul>
        <h4>{icap(tr('payment methods'))+':'}</h4>
        <ul>
          <li><p>{icap(tr('Guarani cash'))}</p></li>
          <li><p>{icap(tr('Guarani, bank deposit'))}</p></li>
          <li><p>{icap(tr('Dollars, PayPal'))}</p></li>
          <li><p>{icap(tr('Dollars, bank deposit'))}</p></li>
        </ul>
        <h4>{icap(tr('registration'))+':'}</h4>
        <p>{icap(tr('online, effective upon receipt of payment'))}</p>
        <p>{icap(tr('Questions?'))}{' '}<AskLink /></p>
      </div>
    </PageLayout>
  )
}

export default CoursePage