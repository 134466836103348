import { React } from 'react'
import { icap, tr } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout.jsx'
import AskLink from '../../../components/AskLink'
import cardPic from '../../../assets/products/webdev.png'

export const card = {
  title: 'webdev:title',
  blurb: 'webdev:blurb',
  subtitle: 'webdev:subtitle',
  // routeBuilder: () => `/${tr('services')}/${'webdev'}`,
  routeBuilder: () => `${tr('webdev-link-kludge')}`,
  imgUrl: cardPic,
  call: 'inquire now',
  ask: <AskLink text={'inquire now'} prefill={'webdev:question'} />
}

const ServicePage = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr(card.title))}</h1>
        <h2>{tr(card.subtitle)}</h2>
        <p>
          {tr('tbd')}
        </p>
        <p>{icap(tr('Questions?'))}{' '}<AskLink /></p>
      </div>
    </PageLayout>
  )
}

export default ServicePage