import { useRef } from "react"
import { useNostrEvents, dateToUnix } from "nostr-react"
import Marquee from "react-fast-marquee"
import { globals, icap, nbsp, slug, tr } from '../../global-pure.js'

const NostrFeed = () => {
  const now = useRef(new Date()); // Make sure current time isn't re-rendered
  const asof = useRef(new Date(Date.now() - 10 * 3600 * 1000)) // 10 hours

  // const { events } = useNostrEvents({
  //   filter: {
  //     since: dateToUnix(now.current), // all new events from now
  //     kinds: [1],
  //   },
  // });

  const { events } = useNostrEvents({
    filter: {
      authors: [
        "51c63606c483dc9b44373e8ea240494b8101e4b23da579f17fec195029207e99",
      ],
      since: 0,
      since: dateToUnix(asof.current),
      kinds: [1,6],
    },
  });
  let event, url, msg, video, color, hashBitcoin
  if (events.length) {
    function stripHashtags(str) {
      return str.replace(/#[^\s]*/g,'')
    }
    function stripUrls(str) {
      return str.replace(/http(s?):\/\/[^\s]*/g,'')
    }
    function getUrl(str) {
      const isUrl = word => {
          const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
          return word.match(urlPattern)
      }
      const words = str.replace('\n',' ').split(' ')
      const urls = words.filter(w => isUrl(w))
      if (urls.length >= 1) return urls[0]
    }
    msg = events.map(e => {
      switch (e.kind) {
        case 1: return stripHashtags(stripUrls(e.content))
        case 6: return stripHashtags(stripUrls(JSON.parse(e.content).content))
      }
    }).filter(item => item.trim() != '').join(' — — — ')
    // event = events[0]
    // function consume(str, search) {
    //   let match = str.match(search)
    //   if (match?.length) {
    //     return { str: str.replace(match[0], ''), match: match[0] }
    //   } else {
    //     return { str, match: undefined }
    //   }
    // }
    // function consumeHash(str, search) {
    //   let match = str.toLowerCase().match(search.toLowerCase())
    //   if (match?.length) {
    //     return { str: str[0]=='#'?str.substr(2):str, match: match[0] }
    //   } else {
    //     return { str, match: undefined }
    //   }
    // }
    // function detect(str, search) {
    //   let match = str.match(search)
    //   if (match?.length) {
    //     return { str, match: match[0] }
    //   } else {
    //     return { str, match: undefined }
    //   }
    // }
    // msg = event.kind + ',' + event.content;
    // ({ str: msg, match: url } = consume(msg, 'https?://[a-z]+\.[a-z]+[^\s]*'));
    // ({ str: msg, match: hashBitcoin } = consumeHash(msg, 'bitcoin'));

    // video = url.includes('://youtu.be') || url.includes('://youtube.com')
    // color = hashBitcoin ? '#FA0' : '#FF0'
    color = '#FF0'
    const urls = events.map(e => {
      switch (e.kind) {
        case 1: return getUrl(e.content)
        case 6: return getUrl(JSON.parse(e.content).content)
      }
    }).filter(item => item.trim() != '')
    url = urls.length ? urls[0] : "https://mercado.satoshidnc.com"
  }
  const marqueeSpacing = '5rem'

  return (
    <>
      {
        url
        ?
          <a href={url} style={{ backgroundColor: color }} target='_blank'>
            <Marquee className="dnc__nostrMarquee" speed={25} autoFill>
            {video
              ? (<>
                <span style={{ fontWeight: 900, fontStyle: 'italic', marginRight: marqueeSpacing }}>{tr('Featured video!')}</span>
                <span style={{ fontWeight: 900, fontStyle: 'italic', marginRight: marqueeSpacing }}>{tr('Click to watch now!')}</span>
                </>)
              : (<></>)}
            </Marquee>
            <Marquee className="dnc__nostrMarquee" speed={100} autoFill>
              <p style={{ marginRight: marqueeSpacing }} >{msg}</p>
            </Marquee>
          </a>
        :
          <Marquee className="dnc__nostrMarquee" speed={100} autoFill>
            <p style={{ marginRight: marqueeSpacing }}>{msg}</p>
          </Marquee>
      }
    </>
  )
}

export default NostrFeed