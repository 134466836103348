import { React } from 'react'
import './library.css'
import { getLang, slug, tr } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout'
import Paragraph from '../../components/page/Paragraph'
import { card as bookConociendoBitcoin } from './conociendo-bitcoin/Book'
import { card as bookConociendoBitcoinII } from './conociendo-bitcoin-ii/Book'
import { card as bookElPatronBitcoin } from './el-patron-bitcoin/Book.jsx'
import Book from '../../components/book/Book'

// Ayn Rand on Money
// https://youtu.be/Z9HbQsTgrtk

const Library = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{tr('library:title')}</h1>
        <Paragraph text={tr('library:intro')} />
        <br />
        <PhysicalBooks />
        <h2>{tr('library:other-title')}</h2>
        <p>{tr('library:other-intro')}</p>
        <br />
        <OnlineBooks />
      </div>
    </PageLayout>
  )
}

const PhysicalBooks = () => {
  return (
    <div className='dnc__blog-container'>
      <div className='dnc__blog-course_groupB'>
        <Book card={bookElPatronBitcoin} />
      </div>
    </div>
  )
}

const OnlineBooks = () => {
  return (
    <div className='dnc__blog-container'>
      <div className='dnc__blog-course_groupB'>
        <Book card={bookConociendoBitcoin} />
        <Book card={bookConociendoBitcoinII} />
      </div>
    </div>
  )
}


export default Library