import React from 'react'
import { slug, tr } from '../../global-pure.js'

const Section = ({ sec }) => {
  return (
    <>
      {tr(sec.heading)
        ? (
          <h2>{tr(sec.heading)}</h2>
        )
        : null
      }
      {[...Array(20).keys()].map((_, par) => tr(sec.heading+'.'+par) !== sec.heading+'.'+par ? (<Content key={sec.heading+'.'+par} sec={sec} par={par} />) : null )}
    </>
  )
}

const Content = ({ sec, par }) => {
  if (tr(sec.heading+'.'+par).startsWith('img:[')) {
    return (<Image sec={sec} par={par} />)
  } else if (tr(sec.heading+'.'+par).startsWith('{listitem}')) {
    return (<Paragraph sec={sec} par={par} indent />)
  } else if (tr(sec.heading+'.'+par).startsWith('btn:[')) {
    let markdown = tr(sec.heading+'.'+par)
    let i = markdown.indexOf('](')
    let caption = markdown.substr(5,i-5)
    let url = markdown.substr(i+2,markdown.length-i-3)
    return (<Button link={url} label={caption} />)
  } else {
    return (<Paragraph sec={sec} par={par} />)
  }
}

const Image = ({ sec, par }) => {
  let markdown = tr(sec.heading+'.'+par)
  let i = markdown.indexOf('](')
  let caption = markdown.substr(5,i-5)
  let url = markdown.substr(i+2,markdown.length-i-3)
  return (<p className='image-container'><img src={url} alt={caption} /></p>)
}

const Paragraph = ({ sec, par, indent }) => {
  const style = indent? { paddingLeft: '2em' }: {}
  let text = tr(sec.heading+'.'+par)
  text = text.replace('{listitem}', '')
  const result = []
  if (text.match(/{link:.*}/)) {
    let parts = text.split('{link:')
    result.push(parts[0])
    for (let i = 1; i < parts.length; i++) {
      const fields = parts[i].substr(0, parts[i].indexOf('}')).split('^')
      result.push(<a key={i} href={fields[0].startsWith('http')?fields[0]:`/${fields[0].split('/').map(s => slug(s)).join('/')}`} target='_blank' noopener noreferrer>{tr(fields[1])}</a>)
      result.push(parts[i].substr(parts[i].indexOf('}')+1))
    }
    return (<p style={style}>{result}</p>)
  }
  return (<p style={style}>{text}</p>)
}

export const Button = ({ link, label }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <a role='button'
      style={{ cursor: 'pointer', border: 'none', padding: '10px 20px', borderRadius: '7px', backgroundColor: '#6C6' }}
      href={link}
      target='_blank' noopener='true' noreferrer='true'
      >{label}</a>
    </div>
  )
}

export default Section
