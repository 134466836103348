import { React } from 'react'
// import './services.css'
import { icap, tr, slug } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout'
import { card as walletCard } from './wallet/Product.jsx'
import { card as posCard } from './pos/Product.jsx'
import Card from '../../../components/card/Card'
import cardPic from '../../../assets/apps/ng.jpg'

export const card = {
  title: 'ng:title',
  blurb: 'ng:blurb',
  subtitle: 'ng:subtitle',
  routeBuilder: () => `/${slug(tr('apps'))}/${slug(tr('nuevoguarani'))}`,
  imgUrl: cardPic,
  call: 'see apps',
  // ask: <AskLink text={'purchase now'} prefill={'ant-s19j:question'} />
}

const NewGuarani = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr('apps'))}</h1>
        <Blog />
      </div>
    </PageLayout>
  )
}

const Blog = () => {
  return (
    <div className='dnc__blog-container'>
      <div className='dnc__blog-course_groupB'>
        <Card card={walletCard} />
        <Card card={posCard} />
      </div>
    </div>
  )
}

export default NewGuarani