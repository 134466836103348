import { React } from 'react'
import { icap, tr } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout.jsx'
import AskLink from '../../../components/AskLink.jsx'
import cardPic from '../../../assets/products/bitcoin-node.jpg'

export const card = {
  title: 'bitcoin-node:title',
  blurb: 'bitcoin-node:blurb',
  subtitle: 'bitcoin-node:subtitle',
  affiliateNote: 'bitcoin-node:affiliate',
  routeBuilder: () => 'https://mercado.satoshidnc.com/nostrmarket/market?naddr=naddr1qqjx2c3ev4snveph95erzwfh956xxdr994skzvr995mkzwf3v4snqerp8p3n2qg6waehxw309aex2mrp0yh8xct5daeks6tyde3jucm0d5pzpmmgnmsg2g7875d864xp4vp0dpd2xg8jphl9w70xp7jr3azf2k55qvzqqqr4gvjj6jhm&merchant=589cadfcea941b5a5b1470b82235b02c33d0912e5d1a3781a6a97b6727005ce9&stall=oP94SLUknxei74W95doi4K&product=jBXE9nEX9XuVCkpsR6JgTy#/',
  imgUrl: cardPic,
  call: 'product details',
  // ask: <AskLink text={'purchase now'} prefill={'ant-s19j:question'} />
}

const ProductPage = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr(card.title))}</h1>
        <h2>{tr(card.subtitle)}</h2>
        <p>
          {tr('tbd')}
        </p>
        <p>{icap(tr('Questions?'))}{' '}<AskLink /></p>
      </div>
    </PageLayout>
  )
}

export default ProductPage