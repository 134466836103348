import React from 'react'
import './card.css'
import { icap, tr } from '../../global-pure.js' 

const Card = ({ card, children, nodate }) => {
  return (
    <div className='dnc__blog-container_card'>
      <div className='dnc__blog-container_card-image'>
      <a href={card.routeBuilder?card.routeBuilder():''}><img src={card.imgUrl} alt={card.imgAlt} /></a>
      </div>
      <div className='dnc__blog-container_card-content'>
        <div>
          {nodate||!card.date?(<></>):(<p>{icap(tr(card.dateType || 'date')) + ': ' + icap(tr(card.date))}</p>)}
          <h3><a href={card.routeBuilder?card.routeBuilder():''}>{icap(tr(card.title))}</a></h3>
          <h4>{tr(card.desc || card.blurb || card.subtitle)}</h4>
          {children}
        </div>
        {card.affiliateNote?(<h4>{tr(card.affiliateNote)}</h4>):(<></>)}
        <p>
          {card.ask?(card.ask):(<a href={card.routeBuilder?card.routeBuilder():''}>{icap(tr(card.call || 'read full article'))}</a>)}
        </p>
      </div>
    </div>
  )
}

export default Card