import React, { Fragment } from 'react'
import './book.css'
import { icap, tr } from '../../global-pure.js' 

const Book = ({ card, children, nodate, noauthor }) => {
  return (
    <div className='dnc__blog-container_book'>
      <div className='dnc__blog-container_book-image'>
      <Link card={card}><img src={card.imgUrl} alt={card.imgAlt} /></Link>
      </div>
      <div className='dnc__blog-container_book-content'>
        <div>
          <h3><Link card={card}>{icap(tr(card.title))}</Link></h3>
          <h4>{tr(card.desc || card.blurb || card.subtitle)}</h4>
          {noauthor||!card.author?(<></>):(<p className='dnc__blog-container_book-author'>{tr(card.author).split(',').map((val, idx) => {
            return (
              <Fragment key={`${val}${idx}`}>
                {val}
                {<br />}
              </Fragment>
            );
          })}</p>)}
          {nodate||!card.date||!tr(card.date)?(<></>):(<p className='dnc__blog-container_book-date'>{icap(tr(card.dateType || 'date')) + ': ' + icap(tr(card.date))}</p>)}
          {children}
        </div>
        {card.affiliateNote?(<h4>{tr(card.affiliateNote)}</h4>):(<></>)}
        <p>
          {card.ask?(card.ask):(<Link card={card}>{icap(tr(card.call || 'read full article'))}</Link>)}
        </p>
      </div>
    </div>
  )
}

const Link = ({ card, children }) => {
  return (<a href={card.routeBuilder?.()} target={card.routeBuilder?.().startsWith('http')?'_blank':undefined}>{children}</a>)
}

export default Book