import React from 'react'
import { slug, tr } from '../../global-pure.js'
import Section from './Section'

const PageContent = ({ def, children }) => {
  return (
    <>
      {def.sections.map((sec) => (<Section key={sec.heading} sec={sec} />))}
      {children}
    </>
  )
}

export default PageContent
