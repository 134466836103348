import './style.css'
import { useEffect, useRef, useState, React } from 'react'
import { useSearchParams } from 'react-router-dom'
import { dmSatoshi, icap, tr } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout.jsx'
import cardPic from '../../assets/products/compfix.png'

export const card = {
  title: 'contact:title',
  blurb: 'contact:blurb',
  subtitle: 'contact:subtitle',
  routeBuilder: () => `/${tr('contact')}`,
  imgUrl: cardPic,
  call: 'write us now'
}

const ContactForm = () => {
  const [query] = useSearchParams();
  const button = useRef(null)
  const contact1 = useRef(null)
  const contact2 = useRef(null)
  const [formStatus, setFormStatus] = useState(icap(tr('send')))
  const [frozen, setFrozen] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus(icap(tr('Thank You!')))
    setFrozen(true);
    const { nostr, name, email, message } = e.target.elements
    dmSatoshi([
      nostr.value?icap(tr('nostr'))+': '+nostr.value:'',
      name.value?icap(tr('name'))+': '+name.value:'',
      email.value?icap(tr('e-mail'))+': '+email.value:'',
      message.value?icap(tr('message'))+':\n'+message.value:''
    ].filter(v => v).join("\n"))
    // let conFom = {
    //   nostr: nostr.value,
    //   name: name.value,
    //   email: email.value,
    //   message: message.value,
    // }
    // console.log(conFom)
  }
  function updateEnabledState() {
    setEnabled(!query.get('n') || contact1.current.value!=='' || contact2.current.value!=='')
  }
  useEffect(() => {
    updateEnabledState()
  })

  return (
    <PageLayout>
    <div className="container">
      <h2>{icap(tr('contact form'))}</h2>
      <form className="form" onSubmit={onSubmit}>
        <p className="item">{icap(tr('How would you like to be contacted?'))} ({tr(query.get('n')?'required':'optional')})</p><div className="break" />
        <label className="item form-label" htmlFor="nostr">{icap(tr('nostr'))}</label>
        <input className="item form-control" type="text" id="nostr" ref={contact1} disabled={frozen} onChange={updateEnabledState} /><div className="break" />
        <div className="break" /><div className="center">or</div><div className="break" />
        <label className="item form-label" htmlFor="name">{icap(tr('name'))}</label>
        <input className="item form-control" type="text" id="name" disabled={frozen} /><div className="break" />
        <label className="item form-label" htmlFor="email">{icap(tr('e-mail'))}</label>
        <input className="item form-control" type="email" id="email" ref={contact2} disabled={frozen} onChange={updateEnabledState} /><div className="break" />
        <p className="item">{icap(tr('What is your message?'))} ({tr('required')})</p><div className="break" />
        <label className="item form-label" htmlFor="message">{icap(tr('message'))}</label>
        <textarea className="item form-control" id="message" disabled={frozen} rows={10} required defaultValue={query.get('m')} /><div className="break" />
        <div className="buttonbox"><button className="item btn btn-danger" type="submit" ref={button} disabled={frozen||!enabled}>{enabled?formStatus:icap(tr('check required fields'))}</button></div>
      </form>
    </div>
    </PageLayout>
  )
}

export default ContactForm