import { React, useState, useEffect } from 'react'
import { slug } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout'
import License from '../../assets/SatoshiFairwareLicense.txt';

export const card = {
  date: 'Jan. 12, 2024',
  title: 'lic:title',
  blurb: 'lic:blurb',
  routeBuilder: () => `/${slug('licenses')}`,
  imgUrl: undefined,
  prefix: 'lic'
}

const SatoshiFairwareLicense = () => {
  const [text, setText] = useState()
  useEffect(() => {
    fetch(License)
      .then((r) => r.text())
      .then((lic) => {
        console.log(lic)
        setText(lic)
      });
  }, [])
  return (
    <PageLayout info={card}>
      <pre>{text}</pre>
    </PageLayout>
  )
}

export default SatoshiFairwareLicense