import './style.css'
import { React, useState } from 'react'
import { icap, tr, hsec, hpub } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout.jsx'
import { FilePond } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import { finalizeEvent, verifyEvent } from 'nostr-tools/pure'
import { hexToBytes } from '@noble/hashes/utils'
import { Relay } from 'nostr-tools/relay'
import { SimplePool } from 'nostr-tools/pool'

const ANNOUNCEMENT_RELAYS = ['relay.satoshidnc.com']
const BROKER_PUBKEY = '596812b100c487c0c3646e7e3a523688cb8c5d3e87b4ffdbf240dad7903033e9'
const BROKER_RELAYS = ['relay.satoshidnc.com']

const TRUSTED_BROKERS = [
  '6b3672f55655cfa7efa15ff0b9d1fad6ce93b5662f1c077b59a4a2755ef449dc', // broker-dvm on satoshidnc.com
]

function toObj(data) {
	let obj = {}
	for (let [key, value] of data) {
		if (obj[key] !== undefined) {
			if (!Array.isArray(obj[key])) {
				obj[key] = [obj[key]]
			}
			obj[key].push(value)
		} else {
			obj[key] = value
		}
	}
	return obj
}

const sendEvent = (signedEvent, targetRelays, callback) => {
  let count = 0
  targetRelays.map(r => {
    const url = r.indexOf(':')?r:'wss://'+r
    console.log(`Connecting to ${url}`)
    const relay = new Relay(url)
    relay.connect(url).then(() => {
      relay.publish(signedEvent).then(() => {
        relay.close()
        finishGate()
      }).catch(e => {
        console.warn(e)
        finishGate()
      })
    }).catch(e => {
      console.warn(e)
      finishGate()
    })
  })
  const finishGate = () => {
    console.log(count, 'finishGate')
    if (count == 0) {
      console.log(signedEvent)
    }
    count++
    if (count == targetRelays.length) {
      callback(signedEvent)
    }
  }
}

const sendAnnouncement = (title, description, coverImageUrl, callback) => {
  sendEvent(finalizeEvent({
    kind: 1,
    created_at: Math.floor(Date.now() / 1000),
    content: `🎺 ${title}\n${description}\n${coverImageUrl}`,
    tags: [
      [ 'relays', ...ANNOUNCEMENT_RELAYS.map(r => r.indexOf(':')?r:'wss://'+r) ],
      [ 'zap', hpub(),             'wss://relay.satoshidnc.com', '99' ],
      //[ 'zap', TRUSTED_BROKERS[0], 'wss://relay.satoshidnc.com',  '1' ],
    ],
  }, hexToBytes(hsec())), ANNOUNCEMENT_RELAYS, callback)
}

const sendBrokerageRequest = (eventId, eventRelay, payloadSize, callback) => {
  sendEvent(finalizeEvent({
    kind: 5068, // brokerage request
    created_at: Math.floor(Date.now() / 1000),
    content: ``,
    tags: [
      [ 'i', `${eventId}`, 'event', eventRelay, 'zap_target' ],
      [ 'i', `${payloadSize}`, 'text', '', 'payload_size' ],
      [ 'output', 'application/json' ],
      [ 'relays', ...BROKER_RELAYS.map(r => r.indexOf(':')?r:'wss://'+r) ],
    ],
  }, hexToBytes(hsec())), BROKER_RELAYS, callback)
}

const receiveBrokerStatus = (requestEventId, callback, timeout) => {
  const pool = new SimplePool()
  const s = pool.subscribeMany(
    [...BROKER_RELAYS.map(r => r.indexOf(':')?r:'wss://'+r)],
    [{ kinds: [7068] }],
    {
      onevent(event) {
        if (event.tags.filter(tag => tag[0] == 'e')[0][1] == requestEventId
        && event.tags.filter(tag => tag[0] == 'p')[0][1] == hpub()) {
          callback(event)
        }
      }
    }
  )
  setTimeout(() => {
    callback(undefined)
    s.close()
  }, timeout || 10000)
}

const brokerAccepted = () => {
  console.log(`Broker accepted`)
}

const UploadForm = () => {

  const [data, setData] = useState()

  const jobrequest = finalizeEvent({
    kind: 5000,
    created_at: Math.floor(Date.now() / 1000),
    content: '',
    tags: [
      [ 'output', 'application/json' ],
      [ 'relays', 'wss://relay.satoshidnc.com' ],
    ],
  }, hexToBytes(hsec()))
  
  return (
    <PageLayout>
    <div className="container">
      {!data?(<>
        <h2>{icap(tr('upload form'))}</h2>
        <form>
          <FilePond name="cover" allowMultiple={false} server="/upload-api" />
          <FilePond name="audio" allowMultiple={false} server="/upload-api" />
          <input name="title" /> (title)<br/>
          <textarea name="description"></textarea> (description)<br/>
          <input name="cost_sats" /> (cost in sats)<br/>
          <input type="hidden" name="jobrequest" value={JSON.stringify(jobrequest)}/>
        </form>
        <button onClick={() => {
          const data = new FormData(document.getElementsByTagName("form")[0])
          console.log(JSON.stringify(toObj(data)))
          console.log('Storage DVM is processing...')
          fetch('/upload-api', {
            method: 'post',
            body: data,
          }).then(resp => {
            if (resp.ok) {
              resp.json().then(json => {
                sendAnnouncement(json.fields.title[0], json.fields.description[0], json.media.coverUrl, announcement => {
                  const payload = `${json.media.audioUrl}`
                  sendBrokerageRequest(announcement.id, ANNOUNCEMENT_RELAYS[0], payload.length, brokerageRequest => {
                    console.log(JSON.stringify(brokerageRequest))
                    console.log('Waiting for a broker to respond')
                    const responses = []
                    let accepted = false
                    receiveBrokerStatus(brokerageRequest.id, statusEvent => {
                      if (statusEvent) {
                        if (responses.filter(r => r.id == statusEvent.id).length == 0 && verifyEvent(statusEvent)) {
                          responses.push(statusEvent)
                          console.log(`Broker responded ${statusEvent.pubkey}`)
                          if (TRUSTED_BROKERS.includes(statusEvent.pubkey) && statusEvent.tags.filter(tag => tag[0] == 'status')[0][1] == 'success') {
                            console.log(`${JSON.stringify(statusEvent)}`)
                            accepted = true
                            brokerAccepted()
                          }
                        }
                      } else if (!accepted) {
                        if (responses.length > 0) {
                          console.log('Responses:')
                          responses.map(e => console.log(JSON.stringify(e)))
                        } else {
                          console.log('No brokers responded')
                        }
                      }
                    })
                  })
                })
              }).catch(e => {
                console.error(e)
              })
            } else {
              // console.error(`${resp.status}: ${resp.statusText}`)
            }
          }).catch(e => {
            console.error(e)
          })
        }}>Upload</button>
      </>):(<>
        <button onClick={() => {
          console.log(JSON.stringify(data.announcement))
        }}>Log Announcement</button>
      </>)}
    </div>
    </PageLayout>
  )
}

export default UploadForm
