import { React } from 'react'
import '../services/services.css'
import { icap, tr } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout'
import Card from '../../components/card/Card'
import Page from '../../components/page/Page'
import NostrFeed from '../../components/nostrfeed/NostrFeed'
import PageContent from '../../components/page/PageContent'
import market from '../../assets/market.png'
import '../../components/brands/brands.css'
import * as vendors from './vendors'

export const card = {
  imgUrl: market,
  title: 'market:title',
  subtitle: 'market:subtitle',
  call: 'browse now',
  routeBuilder: () => `/${tr('market')}`,
}

export const outline1 = [
  { title: card.title, sections: [
    { heading: '(default)' },
  ] },
]

export const outline2 = [
  { title: card.title, sections: [
    { heading: 'What makes this a great platform' },
    { heading: 'How does Satoshi profit?' },
    { heading: 'How it works-customers and clients' },
    { heading: 'How it works-businesses' },
    { heading: 'Get started' },
  ] },
]

const Market = () => {
  return (
    <>
    <NostrFeed />
    <Page title={outline1[0].title}>
      <PageContent def={outline1[0]} />
      <Vendors />
      <PageContent def={outline2[0]} />
    </Page>
    </>
  )
}

const Vendors = () => {
  return (
    <div className='dnc__vendor'>
      <Vendor pubkey='1ad6215247222d1c302ad48e4d7bffe556e8f0ad0433541540130ee4b436927e' stall='hxzcozwySWWkyJDyj3ygfK' src={vendors.granjaNubeBlanca} alt='Granja Nube Blanca' />
      <Vendor pubkey='b018c3ff5347e29389ce29098219a546937fb706779939dd4b7438c7387d8c75' stall='idvSVdhAbA599dV5gK38VU' src={vendors.tiendaGeneral} alt='Tienda General' />
      <Vendor pubkey='e099882085e9f43b5d6171c63c560dbc9c96dc49612590693d1477a4bae2a215' stall='mny7wbCPVVkLzHzXHxbUZb' src={vendors.moreEnergy} alt='More Energy' />
      {/* <Vendor pubkey='34132eb2c3debae8d3137612dc3b66055c208c36f30a6f583928917e428abd4d' stall='RHmaUSrSRJojinEsLgrVbk' src={vendors.casaVegana} alt='Casa Vegana' /> */}
    </div>
  )
}

const Vendor = ({ pubkey, stall, src, alt }) => {
  return (
    <div>
      <a href={'https://mercado.satoshidnc.com/nostrmarket/market?naddr=naddr1qqjx2c3ev4snveph95erzwfh956xxdr994skzvr995mkzwf3v4snqerp8p3n2qg6waehxw309aex2mrp0yh8xct5daeks6tyde3jucm0d5pzpmmgnmsg2g7875d864xp4vp0dpd2xg8jphl9w70xp7jr3azf2k55qvzqqqr4gvjj6jhm&merchant=' + pubkey + '&stall=' + stall} target='_blank' noreferrer nofollow>
        <img src={src} alt={alt} />
      </a>
    </div>
)
}

export default Market