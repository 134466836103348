import { React } from 'react'
import './learning-materials.css'
import { getLang, slug, tr } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout'
import { card as basicsCard } from './basics/Course'
import { card as buildersCard } from './builders/Course'
import { card as businessCard } from './business/Course'
import { card as distrosCard } from './distros/Course'
import { card as investorsCard } from './investors/Course'
import { card as wCard } from '../courses/why/Why'
import Card from '../../components/card/Card'

// Ayn Rand on Money
// https://youtu.be/Z9HbQsTgrtk

const LearningMaterials = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{tr('Bitcoin Courses')}</h1>
        <Blog />
        <p>{tr('courses:intro')}</p>
        <ul>
          <li>
            <a href={`/${slug('courses')}/${slug('why')}`}>{tr(wCard.title)}</a>
            <p>{tr(wCard.blurb)}</p>
          </li>
        </ul>
        <h2>{tr('Course Selection')}</h2>
        <p>{tr('Course Selection.1')}</p>
        <p>{tr('Course Selection.2')}</p>
        <h2>{tr('Refund Policy')}</h2>
        <p>{tr('Refund Policy.1')}</p>
        <p>{tr('Refund Policy.2')}</p>
      </div>
    </PageLayout>
  )
}

const Blog = () => {
  return (
    <div className='dnc__blog-container'>
      <div className='dnc__blog-course_groupB'>
        <Card card={basicsCard} />
        <Card card={businessCard} />
        <Card card={distrosCard} />
        <Card card={buildersCard} />
        <Card card={investorsCard} />
      </div>
    </div>
  )
}


export default LearningMaterials