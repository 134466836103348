import React from 'react'
import './blog.css'
import { Card } from '../../components'
import { card as bbCard } from '../../content/courses/basics/Course'
import { card as tcCard } from '../../content/services/technical-consultation/TechnicalConsultation'
import { card as wCard } from '../../content/courses/why/Why'
import { card as businessCard } from '../../content/courses/business/Course'
import { card as distrosCard } from '../../content/courses/distros/Course'

const Blog = () => {
  return (
    <div className='dnc__blog section__padding'>
      {/*<div className='dnc__blog-heading'>
        <h1 className='gradient__text'>A lot is happening...</h1>
  </div>*/}
      <div className='dnc__blog-container content'>
        <div className='dnc__blog-container_groupA'>
          <Card card={wCard} />
        </div>
        <div className='dnc__blog-container_groupB'>
          <Card card={bbCard} />
          <Card card={tcCard} />
          <Card card={businessCard} />
          <Card card={distrosCard} />
        </div>
      </div>
    </div>
  )
}

export default Blog