import { React } from 'react'
import { icap, tr } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout.jsx'
import AskLink from '../../../components/AskLink.jsx'
import cardPic from '../../../assets/books/el-patron-bitcoin.jpg'

export const card = {
  dateType: 'publication date',
  author: 'el-patron-bitcoin:author',
  date: 'el-patron-bitcoin:date',
  title: 'el-patron-bitcoin:title',
  subtitle: 'el-patron-bitcoin:subtitle',
  routeBuilder: () => tr('el-patron-bitcoin:link'),
  imgUrl: cardPic,
  call: 'inquire about check-out'
}

const BookPage = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{tr(card.title)}</h1>
        <h2>{tr(card.subtitle)}</h2>
        <p>{icap(tr('date'))+': '}{tr(card.date)}</p>
        <h4>{icap(tr('course options'))+':'}</h4>
        <ul>
          <li><p>{tr('basics:option1')}</p></li>
          <li><p>{tr('basics:option2')}</p></li>
        </ul>
        <h4>{icap(tr('this course is for'))+':'}</h4>
        <p>{icap(tr('everyone'))}</p>
        <h4>{icap(tr('in this course, you will'))+':'}</h4>
        <ul>
          <li><p>{tr('basics:bullet1')}</p></li>
          <li><p>{tr('basics:bullet2')}</p></li>
          <li><p>{tr('basics:bullet3')}</p></li>
          <li><p>{tr('basics:bullet4')}</p></li>
        </ul>
        <h4>{icap(tr('prerequisites'))+':'}</h4>
        <p>{icap(tr('none'))}</p>
        <h4>{icap(tr('payment methods'))+':'}</h4>
        <p>{icap(tr('Guarani cash'))}</p>
        <h4>{icap(tr('registration'))+':'}</h4>
        <p>{icap(tr('in-person, upon payment'))}</p>
        <p>{icap(tr('Questions?'))}{' '}<AskLink /></p>
      </div>
    </PageLayout>
  )
}

export default BookPage