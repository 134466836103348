import R, { React, useState, useEffect, useMemo, Children } from 'react'
import './events.css'
import { getLang, slug, tr, icap } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout.jsx'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

const localizer = momentLocalizer(moment)

const CURRENT_DATE = moment().toDate()
const HOLIDAY_COLOR = '#fed'
const HOLIDAY_TEXT = '#cba'
const OFFRANGE_TEXT = '#aaa'

let currentMonth = new Date().getMonth()

const ColoredDateCellWrapper = ({children, value}) => {
  let holiday = ''
  if (value.getMonth() == 1-1 && value.getDate() == 3) {
    holiday = 'Bitcoin’s Birthday'
  } else if (value.getMonth() == 1-1 && value.getDate() == 11) {
    holiday = 'Genesis Transaction Day'
  } else if (value.getMonth() == 1-1 && value.getDate() == 14) {
    holiday = 'Lightning Whitepaper Day'
  } else if (value.getMonth() == 5-1 && value.getDate() == 22) {
    holiday = 'Bitcoin Pizza Day'
  } else if (value.getMonth() == 8-1 && value.getDate() == 1) {
    holiday = 'Bitcoin Independence Day'
  } else if (value.getMonth() == 8-1 && value.getDate() == 21) {
    holiday = 'Bitcoin Infinity Day'
  } else if (value.getMonth() == 10-1 && value.getDate() == 31) {
    holiday = 'Bitcoin Whitepaper Day'
  } else if (value.getMonth() == 12-1 && value.getDate() == 18) {
    holiday = 'HODL Day'
  }
  if (holiday) {
    return <><div className='rbc-day-bg rbc-off-range-bg' style={{ backgroundColor: value.getMonth() == currentMonth? HOLIDAY_COLOR: '' }}>
    {children}
    <div className='rpc-row-segment' style={{ marginLeft: '5px' }}><span style={{ position: 'fixed', width: 'calc((100% - var(--site-margin-left) - var(--site-margin-right)) * 0.142857 - 10px)', textOverflow: 'ellipsis', overflow: 'hidden', fontFamily: 'Arial Narrow', color: value.getMonth() == currentMonth? HOLIDAY_TEXT: OFFRANGE_TEXT }}>{holiday}</span></div></div></>
  } else {
    return <>{children}</>
  }
}


const Events = (props) => {
  const eventStyleGetter = function(event, start, end, isSelected) {
    return {
      style: {
        opacity: end >= Date.now()? 1: 0.25,
      }
    }
  }
  const [events, setEvents] = useState([])
  useEffect(() => {
    fetch(`https://lnbits.satoshidnc.com/events/api/v1/events?api-key=b3466019284e4045b9898c995c15344c`, {
      headers: {
        accept: 'application/json'
      }
    }).then(r => {
      if (r.ok) {
        r.json().then(json => {
          const localEvents = json.map(e => {
            const eventTitle = e.info.split('\n').filter(line => line.startsWith('𝗘𝘃𝗲𝗻𝘁𝗼')).map(h => h.substring(h.indexOf(':')+1).trim())[0]
            const eventHours = e.info.split('\n').filter(line => line.startsWith('𝗛𝗼𝗿𝗮')).map(h => h.substring(h.indexOf(':')+1).trim())[0].split('-').map(f => f.trim()).map(h => h.length <= 5? h+':00': h)
            return {
              id: e.id,
              title: tr(eventTitle),
              start: new Date(Date.parse(`${e.event_start_date}T${eventHours[0]}`)),
              end: new Date(Date.parse(`${e.event_end_date}T${eventHours[1]}`)),
            }
          })
          const remoteEvents = [{
            id: 'ENC#1',
            url: 'https://lu.ma/8a9001y5',
            title: tr('ENC Meetup'),
            start: new Date(Date.parse(`2024-08-16T19:30:00`)),
            end: new Date(Date.parse(`2024-08-16T21:30:00`)),
          },{
            id: 'CDE#2',
            url: 'https://lu.ma/o5q7titr',
            title: tr('CDE Meetup'),
            start: new Date(Date.parse(`2024-08-31T19:00:00`)),
            end: new Date(Date.parse(`2024-08-31T23:30:00`)),
          },{
            id: 'ASU#3',
            url: 'https://lu.ma/pj23rd6g',
            title: tr('ASU Meetup'),
            start: new Date(Date.parse(`2024-09-07T19:00:00`)),
            end: new Date(Date.parse(`2024-09-08T00:00:00`)),
          },{
            id: 'ASU#4',
            url: 'https://lu.ma/b5njath2',
            title: tr('ASU Meetup'),
            start: new Date(Date.parse(`2024-10-05T19:00:00`)),
            end: new Date(Date.parse(`2024-10-06T00:00:00`)),
          },{
            id: 'ASU#5',
            url: 'https://lu.ma/90mjbg6u',
            title: tr('ASU Meetup'),
            start: new Date(Date.parse(`2024-11-02T19:00:00`)),
            end: new Date(Date.parse(`2024-11-03T00:00:00`)),
          }]
          setEvents(remoteEvents.concat(localEvents))
        })
      }
    })
  }, [])

  const components = useMemo(() => ({
    dateCellWrapper: ColoredDateCellWrapper//({children}) => { return (<>{children}<span style={{position:'absolute'}}>test</span></>) },
  }), [])

  return (
    <PageLayout>
      <div className='content'>
        <div className="height600">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={eventStyleGetter}
            onSelectEvent={e => {if (e.end > Date.now()) window.open(e.url || `https://lnbits.satoshidnc.com/events/${e.id}`, '_blank').focus()}}
            //dayPropGetter={date => date.getMonth() == 10-1 && date.getDate() == 31 ? { style: { backgroundColor: '#fed' }} : {} }
            components={components}
            onNavigate={(e) => { currentMonth = e.getMonth() }}
          />
        </div>
      </div>
    </PageLayout>
  )
}

export default Events