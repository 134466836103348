import React from 'react'
import './basic-lesson.css'
import { getLang, tr, icap } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout'

export const lessons = [
  { title: 'Magic Internet Money', origUrl: 'https://bitcoinmagazine.com/learn#lesson-1-magic-internet-money', sections: [
    { heading: '(1:intro)' },
    { heading: 'A BRIEF HISTORY' },
    { heading: 'WHAT DOES BITCOIN SOLVE?' },
    { heading: 'THE BITCOIN BLOCKCHAIN' },
    { heading: 'BITCOIN IS SOUND MONEY' },
  ] },
  { title: 'Who Is In Charge Of Bitcoin?', origUrl: 'https://bitcoinmagazine.com/learn#lesson-2-who-is-in-charge-of-bitcoin', sections: [
    { heading: '(2:intro)' },
    { heading: 'WHY DO WE NEED DECENTRALIZATION?' },
    { heading: 'WHAT FIXES THIS?' },
    { heading: 'DECENTRALIZATION IS PRIVACY' },
  ] },
  { title: 'What Is Money?', origUrl: 'https://bitcoinmagazine.com/learn#lesson-3-what-is-money', sections: [
    { heading: '(3:intro)' },
    { heading: 'LEARNING FROM THE RAI STONES ON YAP ISLAND' },
    { heading: 'MODERN HYPERINFLATION' },
  ] },
  { title: 'The Magic Number — 21 Million', origUrl: 'https://bitcoinmagazine.com/learn#lesson-4-the-magic-number-21-million', sections: [
    { heading: '(4:intro)' },
    { heading: 'THE MAGIC NUMBER' },
    { heading: 'SUPPLY AND DEMAND' },
  ] },
  { title: 'PoW — Proof Of Work Explained', origUrl: 'https://bitcoinmagazine.com/learn#lesson-5-pow-proof-of-work-explained', sections: [
    { heading: '(5:intro)' },
    { heading: 'WORKING SELFISHLY FOR THE GREATER GOOD' },
    { heading: 'LOOKING FOR DIAMONDS' },
    { heading: 'MINING POOLS' },
    { heading: 'ADJUSTING FOR DIFFICULTY' },
  ] },
  { title: 'The Halving — Bitcoin’s Mining Schedule', origUrl: 'https://bitcoinmagazine.com/learn#lesson-6-the-halving-bitcoin-s-mining-schedule', sections: [
    { heading: '(6:intro)' },
    { heading: 'WHAT DOES MINING LOOK LIKE?' },
    { heading: 'THE INCOMING SUPPLY OF NEW BITCOIN' },
    { heading: 'THE HALVING: A FOUR YEAR CYCLE' },
  ] },
  { title: 'Bitcoin Full Nodes — “Verify, Don’t Trust”', origUrl: 'https://bitcoinmagazine.com/learn#lesson-7-bitcoin-full-nodes-verify-don-t-trust', sections: [
    { heading: '(7:intro)' },
    { heading: 'THE PURPOSE OF NODES IN A NETWORK' },
    { heading: 'RUNNING YOUR OWN FULL NODE!' },
  ] },
  { title: 'Can Bitcoin Be Hacked?', origUrl: 'https://bitcoinmagazine.com/learn#lesson-8-can-bitcoin-be-hacked', sections: [
    { heading: '(8:intro)' },
    { heading: 'THE 51% ATTACK' },
    { heading: 'QUANTUM COMPUTING CONCERNS' },
    { heading: 'WHAT CAN YOU DO TO PROTECT YOUR BITCOIN?' },
  ] },
  { title: 'The Great Fork Wars Of 2017', origUrl: 'https://bitcoinmagazine.com/learn#lesson-9-the-great-fork-wars-of-2017', sections: [
    { heading: '(9:intro)' },
    { heading: '2017: CIVIL WAR' },
    { heading: 'THE SCALING PROBLEM' },
    { heading: 'THE BLOCK SIZE DEBATE' },
    { heading: 'OTHER TYPES OF FORKS' },
  ] },
  { title: 'How Can We Scale Bitcoin?', origUrl: 'https://bitcoinmagazine.com/learn#lesson-10-how-can-we-scale-bitcoin', sections: [
    { heading: '(10:intro)' },
    { heading: 'PROPOSED SOLUTIONS' },
    { heading: 'THE LIGHTNING NETWORK' },
    { heading: 'THE FUTURE OF SCALING PAYMENTS' },
  ] },
  { title: 'Does Bitcoin Have Any “Intrinsic Value”?', origUrl: 'https://bitcoinmagazine.com/learn#lesson-11-does-bitcoin-have-any-intrinsic-value', sections: [
    { heading: '(11:intro)' },
    { heading: 'BITCOIN VS GOLD' },
    { heading: 'WHAT IS THE “INTRINSIC VALUE” OF THE US DOLLAR?' },
    { heading: 'WE DON’T NEED “INTRINSIC VALUE”' },
  ] },
  { title: 'Bitcoin Is Apolitical', origUrl: 'https://bitcoinmagazine.com/learn#lesson-12-bitcoin-is-apolitical', sections: [
    { heading: '(12:intro)' },
    { heading: 'LET’S TALK ABOUT POLITICS' },
    { heading: 'BITCOIN WORKS FOR YOU NO MATTER WHAT YOU BELIEVE IN' },
    { heading: 'BITCOIN GIVES US BACK OUR INDEPENDENCE' },
    { heading: 'SEEING BITCOIN AS A SOLUTION' },
  ] },
  { title: 'Investing In Bitcoin', origUrl: 'https://bitcoinmagazine.com/learn#lesson-13-investing-in-bitcoin', sections: [
    { heading: '(13:intro)' },
    { heading: 'WHAT ARE YOU INVESTING IN EXACTLY?' },
    { heading: 'HOW TO BUY BITCOIN' },
    { heading: 'WHY SHOULD YOU BUY BITCOIN?' },
    { heading: 'INVESTMENT TAKEAWAYS' },
  ] },
  { title: 'Bitcoin Self Custody — Not Your Keys, Not Your Cheese', origUrl: 'https://bitcoinmagazine.com/learn#lesson-14-bitcoin-self-custody-not-your-keys-not-your-cheese', sections: [
    { heading: '(14:intro)' },
    { heading: 'WHY SELF CUSTODY?' },
    { heading: 'WHAT DOES IT MEAN TO “OWN” YOUR BITCOIN?' },
    { heading: 'PRIVATE KEYS EXPLAINED' },
    { heading: 'EASY SELF-CUSTODY METHODS' },
    { heading: 'ADDITIONAL SECURITY MEASURES' },
  ] },
  { title: 'Going Green — How Bitcoin Incentivizes Clean Energy Production', origUrl: 'https://bitcoinmagazine.com/learn#lesson-15-going-green-how-bitcoin-incentivizes-clean-energy-production', sections: [
    { heading: '(15:intro)' },
    { heading: 'BITCOIN MINING INCENTIVIZES CLEAN ENERGY PRODUCTION' },
    { heading: 'EXCESS REGIONAL POWER' },
    { heading: 'HOW BITCOIN USES ENERGY' },
  ] },
  { title: 'Bitcoin Is Freedom', origUrl: 'https://bitcoinmagazine.com/learn#lesson-16-bitcoin-is-freedom', sections: [
    { heading: '(16:intro)' },
    { heading: 'HOW DOES BITCOIN SOLVE OUR GLOBAL PROBLEMS?' },
    { heading: 'VENEZUELA AS A CASE STUDY' },
    { heading: 'ADOPTING SOUND MONEY' },
  ] },
  { title: 'What If Bitcoin Gets Banned?', origUrl: 'https://bitcoinmagazine.com/learn#lesson-17-what-if-bitcoin-gets-banned', sections: [
    { heading: '(17:intro)' },
    { heading: 'WHY WOULD THE GOVERNMENT WANT TO BAN BITCOIN?' },
    { heading: 'THE CONCERN FOR MASS ADOPTION' },
  ] },
  { title: 'How Does Bitcoin Advance The Human Race?', origUrl: 'https://bitcoinmagazine.com/learn#lesson-18-how-does-bitcoin-advance-the-human-race', sections: [
    { heading: '(18:intro)' },
    { heading: 'THE KARDASHEV SCALE' },
    { heading: 'THE EURO AS A CASE STUDY' },
    { heading: 'ELIMINATING TRUST' },
    { heading: 'THE FUTURE OF BITCOIN' },
  ] },
  { title: 'Why Bitcoin Over Any Other Cryptocurrency?', origUrl: 'https://bitcoinmagazine.com/learn#lesson-19-why-bitcoin-over-any-other-cryptocurrency', sections: [
    { heading: '(19:intro)' },
    { heading: 'NETWORK EFFECTS' },
    { heading: 'BITCOIN NEEDS NO CAMPAIGN' },
  ] },
  { title: 'DeFi On Bitcoin', origUrl: 'https://bitcoinmagazine.com/learn#lesson-20-defi-on-bitcoin', sections: [
    { heading: '(20:intro)' },
    { heading: 'CREATING A DECENTRALIZED WEB' },
    { heading: 'HOW WILL THIS HAPPEN?' },
    { heading: 'WHY A DECENTRALIZED INTERNET NEEDS TO BE BUILT ON BITCOIN' },
  ] },
  { title: 'Hyperbitcoinization — Life Under The Bitcoin Standard', origUrl: 'https://bitcoinmagazine.com/learn#lesson-21-hyperbitcoinization-life-under-the-bitcoin-standard', sections: [
    { heading: '(21:intro)' },
    { heading: 'WILL BITCOIN REPLACE FIAT?' },
    { heading: 'CBDCS: CENTRAL BANK DIGITAL CURRENCIES' },
    { heading: 'THE BITCOIN STANDARD' },
  ] },
]

const BasicLesson = ({ number }) => {
  const les = lessons[number-1]
  return ('Muy pronto!')
  // return (
  //   <PageLayout>
  //     <div className='content'>
  //       {
  //         getLang().startsWith('es')
  //         ? (
  //           <>
  //           <h1>{icap(tr('lesson'))} {number}: {tr(les.title)}</h1>
  //           {les.sections.map((sec) => (<Section sec={sec} />))}
  //           {icap(tr('next'))}: <a href={number+1}>{tr(lessons[number].title)}</a>
  //           </>
  //         )
  //         : (<>{tr('basics:desc1')}<a target='_blank' rel='noreferrer' href={lessons[number-1].origUrl}>{tr('basics:desc2')}</a>{tr('basics:desc3')}</>)
  //       }
  //     </div>
  //   </PageLayout>
  // )
}

export default BasicLesson