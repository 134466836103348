import React from 'react'
import './article.css'
import '../../content/courses/learning-materials.css'
import { tr } from '../../global-pure.js' 
import PageLayout from '../page/PageLayout'

const ArticleLayout = ({ children, info }) => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{tr(info.title)}</h1>
        {info.subtitle?(<h2>{tr(info.subtitle)}</h2>):(<></>)}
        {!info.prefix?<></>:
          Array(100).fill(0).map((_, par) => tr(info.prefix+'.'+par) !== info.prefix+'.'+par ? (<Content art={info.prefix} par={par} />) : (<></>) )
        }
        {children}
      </div>
    </PageLayout>
  )
}

const Content = ({ art, par }) => {
  if (tr(art+'.'+par).startsWith('img:[')) {
    return (<Image art={art} par={par} />)
  } else if (tr(art+'.'+par)==='tag:ol') {
    return (<OrderedList list={art+'.'+par} />)
  } else {
    return (<Paragraph par={art+'.'+par} />)
  }
}

const Image = ({ art, par }) => {
  let markdown = tr(art+'.'+par)
  let i = markdown.indexOf('](')
  let caption = markdown.substr(5,i-5)
  let url = markdown.substr(i+2,markdown.length-i-3)
  return (<p className='image-container'><img src={url} alt={caption} /></p>)
}

const OrderedList = ({ list }) => {
  return (
    <ol>
      {Array(100).fill(0).map((_, par) => tr(list+'.'+par) !== list+'.'+par ? (<li><Content art={list} par={par} /></li>) : (<></>) )}
    </ol>
  )
}

const Paragraph = ({ par }) => {
  return (<p>{tr(par)}</p>)
}

export default ArticleLayout