import { React } from 'react'
import { icap, tr } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout.jsx'
import AskLink from '../../../components/AskLink.jsx'
import cardPic from '../../../assets/products/atm-2way.png'

export const card = {
  title: 'atm-2way:title',
  blurb: 'atm-2way:blurb',
  subtitle: 'atm-2way:subtitle',
  affiliateNote: 'atm-2way:affiliate',
  routeBuilder: () => 'https://bitcoincapitalist.io/products/bidirectional-bitcoin-atm-machine?sca_ref=4208387.gcAOvU0XZS&sca_source=satoshidnc.com',
  imgUrl: cardPic,
  call: 'learn more',
  // ask: <AskLink text={'purchase now'} prefill={'atm-2way:question'} />,
}

const ProductPage = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr(card.title))}</h1>
        <h2>{tr(card.subtitle)}</h2>
        <p>
          {tr('tbd')}
        </p>
        <p>{icap(tr('Questions?'))}{' '}<AskLink /></p>
      </div>
    </PageLayout>
  )
}

export default ProductPage