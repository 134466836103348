import { React } from 'react'
import { icap, tr } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout.jsx'
import AskLink from '../../../components/AskLink.jsx'
import cardPic from '../../../assets/products/ant-s19j.png'

export const card = {
  title: 'ant-s19j:title',
  blurb: 'ant-s19j:blurb',
  subtitle: 'ant-s19j:subtitle',
  affiliateNote: 'ant-s19j:affiliate',
  routeBuilder: () => 'https://bitcoincapitalist.io/products/new-antminer-s19-bitcoin-miner-95th-3215w-paraguay?sca_ref=4208387.gcAOvU0XZS&sca_source=satoshidnc.com',
  imgUrl: cardPic,
  call: 'purchase now',
  // ask: <AskLink text={'purchase now'} prefill={'ant-s19j:question'} />
}

const ProductPage = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr(card.title))}</h1>
        <h2>{tr(card.subtitle)}</h2>
        <p>
          {tr('tbd')}
        </p>
        <p>{icap(tr('Questions?'))}{' '}<AskLink /></p>
      </div>
    </PageLayout>
  )
}

export default ProductPage