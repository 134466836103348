import React from 'react'
import { slug, tr } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout'
import Section from './Section'

const Page = ({ title, def, children }) => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{tr(title) || tr(def.title)}</h1>
        {def && def.sections.map((sec) => (<Section key={sec.heading} sec={sec} />))}
        {children}
      </div>
    </PageLayout>
  )
}

export default Page
