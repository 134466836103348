import { React } from 'react'
import { slug } from '../../../global-pure.js'
import ArticleLayout from '../../../containers/article/ArticleLayout'
import cardImg from './card.png'

export const card = {
  date: 'Aug. 26, 2023',
  title: 'why:title',
  blurb: 'why:blurb',
  routeBuilder: () => `/${slug('courses')}/${slug('why')}`,
  imgUrl: cardImg,
  prefix: 'why'
}

const Why = () => {
  return (
    <ArticleLayout info={card} />
  )
}

export default Why