import { React } from 'react'
import { icap, tr } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout.jsx'
import AskLink from '../../../components/AskLink'
import cardPic from '../../../assets/courses/business.png'

export const card = {
  date: 'business-course:date',
  title: 'business-course:title',
  blurb: 'business-course:blurb',
  subtitle: 'business-course:subtitle',
  routeBuilder: () => `/${tr('courses')}/${tr('business')}`,
  imgUrl: cardPic,
  call: 'course details'
}

const CoursePage = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{tr(card.title)}</h1>
        <h2>{tr(card.subtitle)}</h2>
        <p>{icap(tr('dates'))+': '}{tr(card.date)}</p>
        <h4>{icap(tr('schedule'))+':'}</h4>
        <ul>
          <li><p>{tr('business:option1')}</p></li>
        </ul>
        <h4>{icap(tr('this course is for'))+':'}</h4>
        <p>{icap(tr('business owners'))}</p>
        <h4>{icap(tr('this course includes'))+':'}</h4>
        <ul>
          <li><p>{tr('business:bullet1')}</p></li>
          <li><p>{tr('business:bullet2')}</p></li>
          <li><p>{tr('business:bullet3')}</p></li>
          <li><p>{tr('business:bullet4')}</p></li>
          <li><p>{tr('business:bullet5')}</p></li>
        </ul>
        <h4>{icap(tr('NOT included in course'))+':'}</h4>
        <ul>
          <li><p>{tr('business:bulletx1')}</p></li>
          <li><p>{tr('business:bulletx2')}</p></li>
          <li><p>{tr('business:bulletx3')}</p></li>
        </ul>
        <h4>{icap(tr('prerequisites'))+':'}</h4>
        <ul>
          <li><p>{tr('business:prereq1')}</p></li>
          <li><p>{tr('business:prereq2')}</p></li>
        </ul>
        <h4>{icap(tr('payment methods'))+':'}</h4>
        <ul>
          <li><p>{icap(tr('Guarani cash'))}</p></li>
          <li><p>{icap(tr('Guarani, bank deposit'))}</p></li>
        </ul>
        <h4>{icap(tr('registration'))+':'}</h4>
        <p>{icap(tr('in-person, upon payment'))}</p>
        <p>{icap(tr('Questions?'))}{' '}<AskLink /></p>
      </div>
    </PageLayout>
  )
}

export default CoursePage