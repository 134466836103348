import { React } from 'react'
import { icap, tr } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout'
import AskLink from '../../../components/AskLink'
import technicalConsultation from '../../../assets/products/consulta-tecnica.png'

export const card = {
  imgUrl: technicalConsultation,
  title: 'techcons:title',
  subtitle: 'techcons:subtitle',
  call: 'inquire now',
  ask: <AskLink text={'inquire now'} prefill={'techcons:question'} />,
  routeBuilder: () => `/${tr('services')}/${tr('tech')}`,

  desc1a: 'tech-consult:desc1a',
  desc1b: 'tech-consult:desc1b',
  desc1c: 'tech-consult:desc1c',
}

const TechnicalConsultation = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr(card.title))}</h1>
        <h2>{tr(card.subtitle)}</h2>
        <p>
          {tr(card.desc1a)}<a target="_blank" rel="noreferrer" href={tr('market-naddr-link')}>{tr(card.desc1b)}</a>{tr(card.desc1c)}
        </p>
        <p>{icap(tr('Questions?'))}{' '}{card.ask}</p>
      </div>
    </PageLayout>
  )
}

export default TechnicalConsultation