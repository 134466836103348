import React from 'react'
import './brands.css'
import { bitcoin, lightning, lnbits, lnd, nostr, strfry, caddy, react, nodejs } from './imports'

const Brands = () => {
  return (
    <div className='dnc__brand section__padding'>
      <div><img src={bitcoin} alt="bitcoin" /></div>
      <div><img src={lightning} alt="lightning" /></div>
      <div><img src={lnd} alt="lnd" /></div>
      <div><img src={lnbits} alt="lnbits" /></div>
      <div><img src={nostr} alt="nostr" /></div>
      <div><img src={strfry} alt="strfry" /></div>
      <div><img src={react} alt="react" /></div>
      <div><img src={nodejs} alt="nodejs" /></div>
      <div><img src={caddy} alt="caddy" /></div>
    </div>
  )
}

export default Brands