import React from 'react'
import './ids.css'

const IDs = () => {
  return (
    <div className='dnc__id section__padding'>
      <div className='id-box'>
        <span className='id-box-title'>Satoshi, D.N.C. Identities</span>
      </div>
      <div className='id-box-outer'>
        <div className='id-detail-group'>
          <div className='id-box id-fine-print'>
            <span className='id-box-title'>Nostr</span>
            <div className='id-box-border'>
              <span>npub128rrvpkys0wfk3ph8682yszffwqsre9j8kjhnutlasv4q2fq06vsez5dlf</span>
            </div>
          </div>
          {/* <div className='id-box id-fine-print'>
            <span className='id-box-title'>Market</span>
            <div className='id-box-border'>
              <span>7911c65f01ef94b390a951ff7aa9f28c1f7fcbb1c4aa1c92ecf2bf3d223b0678</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default IDs