import { React } from 'react'
// import './services.css'
import { icap, tr } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout'
import { card as antCard } from './ant-s19j/Product'
import { card as atmCard } from './atm-2way/Product'
import { card as bitcoinNodeCard } from './bitcoin-node/Product'
import Card from '../../components/card/Card'

const Products = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr('products'))}</h1>
        <Blog />
      </div>
    </PageLayout>
  )
}

const Blog = () => {
  return (
    <div className='dnc__blog-container'>
      <div className='dnc__blog-course_groupB'>
        <Card card={bitcoinNodeCard} />
      </div>
    </div>
  )
}

export default Products