import { React } from 'react'
import './services.css'
import { icap, tr } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout'
import { card as tcCard } from './technical-consultation/TechnicalConsultation'
import { card as webdevCard } from './webdev/Service'
import { card as compfixCard } from './compfix/Service'
import Card from '../../components/card/Card'

const Services = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr('services'))}</h1>
        <Blog />
      </div>
    </PageLayout>
  )
}

const Blog = () => {
  return (
    <div className='dnc__blog-container'>
      <div className='dnc__blog-course_groupB'>
        <Card card={tcCard} />
        <Card card={webdevCard} />
        <Card card={compfixCard} />
      </div>
    </div>
  )
}

export default Services